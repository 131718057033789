import { motion } from "framer-motion";

const RightColumn = () => {
  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 1.2, delay: 0.4 }}
      className="relative"
    >
      <div className="relative aspect-[4/5] bg-gray-50">
        <img
          src="https://placehold.co/1000x1200/fafafa/e5e5e5?text=zen"
          alt="Minimalist Design"
          className="w-full h-full object-cover"
        />

        <div className="absolute inset-0 bg-gradient-to-t from-white/10 to-transparent" />
        <div className="absolute inset-0 bg-gradient-to-l from-white/10 to-transparent" />
        <div className="absolute inset-0 border border-gray-100 m-8" />
      </div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 1.2 }}
        className="absolute -bottom-8 -left-8 bg-white p-8 shadow-[0_4px_40px_-12px_rgba(0,0,0,0.05)]"
      >
        <div className="flex items-center gap-6">
          <div className="w-1 h-1 rounded-full bg-gray-300" />
          <span className="text-gray-400 text-sm tracking-[0.2em] uppercase">
            Whitespace
          </span>
          <div className="w-1 h-1 rounded-full bg-gray-300" />
        </div>
      </motion.div>
    </motion.div>
  );
};

export default RightColumn;
