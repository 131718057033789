import { motion } from "framer-motion";

const BottomSection = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1, delay: 1.4 }}
      className="mt-40 pt-16 border-t border-gray-50"
    >
      <div
        className="flex desktop:flex-row laptop:flex-row tablet:flex-col mobile:flex-col 
        justify-between items-center gap-12"
      >
        {["Simplicity", "Balance", "Harmony", "Purpose"].map((word, i) => (
          <div key={i} className="flex items-center gap-4">
            <div className="w-1 h-1 rounded-full bg-gray-200" />
            <span className="text-gray-300 text-sm tracking-[0.3em] uppercase">
              {word}
            </span>
          </div>
        ))}
      </div>
    </motion.div>
  );
};

export default BottomSection;
