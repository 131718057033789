import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const LeftColumn = () => {
  return (
    <motion.div
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 1.2, delay: 0.2 }}
      className="flex flex-col desktop:justify-center laptop:justify-center tablet:justify-start mobile:justify-start"
    >
      <div className="space-y-8 max-w-xl">
        <div className="flex items-center gap-4">
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: 40 }}
            transition={{ duration: 1, delay: 0.8 }}
            className="h-[1px] bg-gray-200"
          />
          <span className="text-gray-400 text-xs tracking-[0.25em]">
            MINIMALIST
          </span>
        </div>

        <h1
          className="desktop:text-8xl laptop:text-7xl tablet:text-6xl mobile:text-5xl 
          font-extralight text-gray-800 leading-[1.1] tracking-[-0.02em]"
        >
          Test 6<span className="block text-gray-300 mt-4">Design</span>
        </h1>

        <p className="text-gray-500 text-lg leading-relaxed font-light max-w-md">
          Embracing simplicity and tranquility through thoughtful design. Where
          every element serves a purpose, and space becomes a canvas for
          serenity.
        </p>

        <div className="flex items-center gap-12 pt-8">
          <Link to="/explore" className="group relative">
            <span
              className="block px-10 py-5 text-sm tracking-widest text-gray-700 
              border border-gray-100 group-hover:border-gray-200 transition-colors duration-500"
            >
              EXPLORE
            </span>
            <div
              className="absolute inset-0 bg-gray-50 transform origin-left scale-x-0 
              group-hover:scale-x-100 transition-transform duration-500 -z-10"
            />
          </Link>

          <div className="flex items-center gap-4">
            <div className="w-8 h-[1px] bg-gray-200" />
            <span className="text-gray-400 text-sm">01</span>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default LeftColumn;
