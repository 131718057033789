import BackgroundPattern from "./BackgroundPattern";
import BottomSection from "./BottomSection";
import LeftColumn from "./LeftColumn";
import RightColumn from "./RightColumn";
import TopBar from "./TopBar";

const HeroLayout = () => {
  return (
    <section className="min-h-screen w-full bg-white overflow-hidden">
      <BackgroundPattern />

      <div className="max-w-[1600px] mx-auto px-12 desktop:py-48 laptop:py-40 tablet:py-32 mobile:py-24">
        <TopBar />

        <div
          className="grid desktop:grid-cols-[1fr,1.2fr] laptop:grid-cols-[1fr,1.2fr] tablet:grid-cols-1 mobile:grid-cols-1 
          desktop:gap-32 laptop:gap-24 tablet:gap-20 mobile:gap-16"
        >
          <LeftColumn />
          <RightColumn />
        </div>

        <BottomSection />
      </div>
    </section>
  );
};

export default HeroLayout;
