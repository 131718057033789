const BackgroundPattern = () => {
  return (
    <div className="absolute inset-0 opacity-[0.03]">
      {[...Array(10)].map((_, i) => (
        <div
          key={i}
          className="absolute h-[1px] w-full bg-gray-400"
          style={{ top: `${i * 10}%` }}
        />
      ))}
    </div>
  );
};

export default BackgroundPattern;
