import { motion } from "framer-motion";

const TopBar = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
      className="flex justify-between items-center mb-32"
    >
      <div className="flex items-center gap-8">
        <div className="w-2 h-2 rounded-full bg-gray-200" />
        <span className="text-gray-400 text-xs tracking-[0.5em] uppercase">
          Test
        </span>
      </div>
      <div className="flex items-center gap-8">
        <span className="text-gray-300 text-xs">EST 2023</span>
        <div className="w-8 h-[1px] bg-gray-200" />
      </div>
    </motion.div>
  );
};

export default TopBar;
